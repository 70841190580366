import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Kontaktirajte nas putem telefona ili elektronske pošte.`}</h2>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Direktor" mdxType="AccordionItem">
  <strong>Nedžad Peljto</strong><br />
  Tel: 035 703 186<br />
  Fax: 035 706 996<br />
  Mob: 061 723 290<br />
  </AccordionItem>
  <AccordionItem title="Sekretar - Pravnik" mdxType="AccordionItem">
  Tel: 035 703-186
  </AccordionItem>
  <AccordionItem title="Radio Gračanica - redakcija" mdxType="AccordionItem">
  Glavni i odgovorni urednik: <br />
  <strong>Melina Kurtović</strong> <br />
  Tel: 035 703 500 <br />
  Tel: 035 703 508 <br />
  </AccordionItem>
  <AccordionItem title="Marketing" mdxType="AccordionItem">
  Kontakt osoba:
  <strong>Edim Ahmetagić</strong><br />
  Tel/Fax: 035 706 996
  </AccordionItem>
  <AccordionItem title="Biblioteka Dr. Mustafa Kamarić" mdxType="AccordionItem">
  Šef biblioteke: <br />
  <strong>Senada Letić</strong><br />
  Tel. 035 706 081<br />
  </AccordionItem>
    </Accordion>
    <h2>{`Pošalji e-mail`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={2} colLg={2} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Nedžad Peljto" title="Direktor" aspectRatio="2:1" color="dark" actionIcon="email" href="mailto:bkc.grac@bih.net.ba" mdxType="ResourceCard">

  </ResourceCard>
      </Column>
      <Column colMd={2} colLg={2} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Edim Ahmetagić" title="Marketing" aspectRatio="2:1" actionIcon="email" href="mailto:epp.grac@bih.net.ba" mdxType="ResourceCard">
 
  </ResourceCard>
      </Column>
      <Column colMd={2} colLg={2} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Senada Letić" title="Biblioteka" aspectRatio="2:1" actionIcon="email" href="mailto:gradskabibliotekagracanica@gmail.com" mdxType="ResourceCard">
 
  </ResourceCard>
      </Column>
      <Column colMd={2} colLg={2} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Glavni i odgovorni urednik" title="Radio Gračanica" aspectRatio="2:1" actionIcon="email" href="mailto:radio.grac@bih.net.ba" mdxType="ResourceCard">
    
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      